import * as React from "react";

import PropTypes from "prop-types";

import { Helmet } from "react-helmet";

/**
 * This component is responsible to render meta data to pages. The following
 * meta data will be rendered:
 *
 * - title
 * - description
 * - lang
 * - og:title
 * - og:description
 * - og:type

 * @param {string} title meta title tag, must not be empty, must not be null
 * @param {string} description meta description tag, must not be empty
 * @param {string} lang meta lang tag defined as ISO 639-1 codes tag, must not be empty,
 * must not be null, defaults to "de"
 */
const MetaComponents = ({ title, lang, description, canonical }) => {
    return (
        <Helmet
            base={"https://miracl.at/"}
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={title}
            meta={[
                {
                    name: "description",
                    content: description,
                },
                {
                    property: "og:title",
                    content: title,
                },
                {
                    property: "og:description",
                    content: description,
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:image",
                    content: process.env.GATSBY_SITE_URL + "/og-image-miracl.png",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: title,
                },
                {
                    name: "twitter:image",
                    content: process.env.GATSBY_SITE_URL + "/og-image-miracl.png",
                },
                {
                    name: "twitter:description",
                    content: description,
                },
            ]}
            link={[
                {
                    rel: "apple-touch-icon",
                    sizes: "180x180",
                    href: "/apple-touch-icon.png",
                },
                {
                    rel: "icon",
                    type: "image/png",
                    sizes: "32x32",
                    href: "/favicon-32x32.png",
                },
                {
                    rel: "icon",
                    type: "image/png",
                    sizes: "16x16",
                    href: "/favicon-16x16.png",
                },
            ].concat(canonical && canonical.href ? [{ rel: "canonical", href: canonical.href }] : [])}
        />
    );
};

MetaComponents.defaultProps = {
    lang: "de",
};

MetaComponents.propTypes = {
    description: PropTypes.string.isRequired,
    lang: PropTypes.string,
    title: PropTypes.string.isRequired,
};

export default MetaComponents;
