import React from "react";
import styled from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";

import { colors } from "../../constants/colors";
import { breakpoints } from "../../constants/breakpoints";
import ServicesLinks from "./ServicesLinks";

const DesktopNav = () => {
    return (
        <Wrapper>
            <ul>
                <li>
                    <ServicesLinks />
                </li>
                <li>
                    <Link to="/dienstleistungen/kreditrechner/">
                        <Trans>header.mortgageCalculator</Trans>
                    </Link>
                </li>
                <li>
                    <Link to="/ueber-uns/">
                        <Trans>header.aboutUs</Trans>
                    </Link>
                </li>
                <li>
                    <Link to="/faq/">
                        <Trans>header.faqs</Trans>
                    </Link>
                </li>
            </ul>
        </Wrapper>
    );
};

const Wrapper = styled.nav`
    color: ${colors.primary};
    margin: 0 auto 0 50px;

    > ul {
        list-style: none;
        margin: 0;
        padding: 0;

        > li {
            display: inline;
            margin-left: 20px;

            &:last-child {
                display: none;

                @media (min-width: 800px) {
                    display: inline;
                }
            }
        }
    }
    ul.servicesList {
        background: #fff;
        box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
        padding: 0 20px;
        position: absolute;
        top: 100%;
        z-index: 2;

        li {
            border-top: 1px solid ${colors.primary};
            padding: 12px 0;

            :first-child {
                border-top: 0;
                margin-top: 10px;
            }
            :last-child {
                margin-bottom: 10px;
            }
        }
    }

    a {
        color: ${colors.primary};
    }

    @media (max-width: ${breakpoints.mobileBigMax}) {
        display: none;
    }
`;

export default DesktopNav;
