import React from "react";
import styled, { css } from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";

import MiraclLogo from "../assets/elements/MiraclLogo";
import austriaA4CIcon from "../assets/icons/austria-a-4C-0-8-cm-ug.svg";
import fbIcon from "../assets/icons/facebook.svg";
import igIcon from "../assets/icons/instagram.svg";
import AppleStoreIcon from "../assets/icons/apple-store.svg";
import GooglePlayIcon from "../assets/icons/google-play.svg";
import { ContentWrapper, GoogleReviews } from "../assets/styles/base";
import { breakpoints } from "../constants/breakpoints";
import GtmService from "../service/gtmService";
import GoogleReviewsElement from "./GoogleReviewsElement";

const Footer = () => {
    return (
        <Wrapper>
            <Content>
                <StyledContentWrapper>
                    <Group first>
                        <div>
                            <Link to="/">
                                <MiraclLogo />
                            </Link>
                            <Copyrights>
                                <Trans>footer.copyright</Trans> &copy; {new Date().getFullYear()}{" "}
                                <Link to="/" className="purple">
                                    miracl.at
                                </Link>
                            </Copyrights>
                            <span className="purple">E</span>{" "}
                            <a
                                href="mailto:kontakt@miracl.at"
                                onClick={() => {
                                    GtmService.instance.trackEvent({
                                        event: "contactClickedFooter",
                                    });
                                }}
                            >
                                kontakt@miracl.at
                            </a>
                            <br />
                            <span className="purple">T</span>{" "}
                            <a
                                href="tel:+436703085171"
                                onClick={() => {
                                    GtmService.instance.trackEvent({
                                        event: "phoneClickedFooter",
                                    });
                                }}
                            >
                                +43 670 3085171
                            </a>
                            <SocialIcons>
                                <a
                                    href="https://www.instagram.com/miracl.at/"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                        GtmService.instance.trackEvent({
                                            event: "instagramClicked",
                                        });
                                    }}
                                >
                                    <StyledIgIcon />
                                </a>
                                <a
                                    href="https://www.facebook.com/miracl.at.finanzierung/"
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                        GtmService.instance.trackEvent({
                                            event: "facebookClicked",
                                        });
                                    }}
                                >
                                    <StyledFbIcon />
                                </a>
                            </SocialIcons>
                        </div>
                        <AppLinks>
                            <a
                                href="https://apps.apple.com/at/app/miracl-alles-zu-deinem-kredit/id1609439229"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <AppleStoreIcon height="40" width="130" />
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=at.miracl.web&hl=de_AT&gl=AT"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <GooglePlayIcon height="60" width="140" />
                            </a>
                        </AppLinks>
                    </Group>
                    <Group>
                        <h3>
                            <Trans>footer.servicesSection</Trans>
                        </h3>
                        <ul>
                            <li>
                                <Link to="/dienstleistungen/kreditvergleich/">
                                    <Trans>footer.creditComparison</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/dienstleistungen/umschuldung/">
                                    <Trans>footer.reFinancing</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/dienstleistungen/kreditrechner/">
                                    <Trans>footer.mortgageCalculator</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/artikel/immobilienfinanzierung/">
                                    <Trans>footer.realEastateFinancing</Trans>
                                </Link>
                            </li>
                        </ul>
                    </Group>
                    <Group>
                        <h3>
                            <Trans>footer.usefulSection</Trans>
                        </h3>
                        <ul>
                            <li>
                                <Link to="/kontakt/">
                                    <Trans>footer.contactForm</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/ueber-uns/">
                                    <Trans>footer.aboutUs</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq/">
                                    <Trans>footer.faqs</Trans>
                                </Link>
                            </li>
                            <li>
                                <a href="https://miracl.join.com/" target="_blank" rel="noreferrer">
                                    <Trans>footer.career</Trans>
                                </a>
                            </li>
                            <li>
                                <Link to="/partner/">
                                    <Trans>footer.partner</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/standardinfoblatt/">
                                    <Trans>footer.standardinformationen</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/agb/">
                                    <Trans>footer.gtc</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/datenschutzerklaerung/">
                                    <Trans>footer.privacyStatement</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/impressum/">
                                    <Trans>footer.legalNotice</Trans>
                                </Link>
                            </li>
                            <li>
                                <Link to="/presse/">
                                    <Trans>footer.pressroom</Trans>
                                </Link>
                            </li>
                        </ul>
                    </Group>
                    <Group>
                        <Group inside>
                            <h3>
                                <Trans>footer.knowledge</Trans>
                            </h3>
                            <ul>
                                <li>
                                    <Link to="/artikel/baufinanzierung/">
                                        <Trans>footer.constructionFinance</Trans>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/artikel/wohnkredit/">
                                        <Trans>footer.financing</Trans>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/gut-zu-wissen/">
                                        <Trans>footer.tipsAndTricks</Trans>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/unsere-kunden/">
                                        <Trans>footer.successStories</Trans>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/ratgeber/">
                                        <Trans>footer.blog</Trans>
                                    </Link>
                                </li>
                            </ul>
                        </Group>
                        <Group inside>
                            <h3>
                                <Trans>footer.safetySection</Trans>
                            </h3>
                            <ul>
                                <li>
                                    <span
                                        className="link"
                                        onClick={() => {
                                            window.cookiehub.openSettings();
                                        }}
                                        role="presentation"
                                    >
                                        <Trans>footer.cookieSettings</Trans>
                                    </span>
                                </li>
                            </ul>
                        </Group>
                    </Group>

                    <Group icons>
                        <StyledAustriaIcon />
                        <GoogleReviewsElement />
                    </Group>
                </StyledContentWrapper>
            </Content>
        </Wrapper>
    );
};

const horizontalMin = "510px";
const horizontalSmallMin = "968px";
const horizontalFullMin = "1050px";

const Wrapper = styled.footer`
    margin-top: auto;
`;

const Content = styled.footer`
    background: #f0f0f0;
    color: #555;
    font-size: 15px;
    line-height: 22px;
    margin-top: 50px;
    padding-bottom: 30px;
    padding-top: 40px;

    h3 {
        color: #000;
        font-size: 15px;
        font-weight: bold;
        margin: 4px 0;
    }
    a,
    .link {
        color: #555;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        margin: 4px 0;
    }
    .purple {
        color: #8585fa;
    }

    @media (min-width: ${horizontalMin}) {
        h3 {
            margin-bottom: 15px;
        }
    }
`;

const StyledContentWrapper = styled(ContentWrapper)`
    margin-bottom: 0;
    margin-top: 0;

    @media (min-width: ${horizontalMin}) {
        display: flex;
        flex-wrap: wrap;
    }
`;

const Group = styled.div`
    margin-bottom: 30px;

    @media (min-width: ${horizontalMin}) {
        margin-bottom: 19px;
        width: 50%;
    }

    @media (min-width: ${horizontalSmallMin}) {
        width: 29%;
    }

    @media (min-width: ${horizontalFullMin}) {
        margin-right: ${({ inside }) => (inside ? "0" : "35px")};
        width: auto;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
        margin-right: ${({ inside }) => (inside ? "0" : "60px")};
    }

    ${({ first }) =>
        first &&
        css`
            @media (min-width: ${horizontalMin}) {
                display: flex;
                margin-bottom: 50px;
                flex: 100% 0 0;
                width: 100%;

                > div {
                    width: 50%;
                }
            }
            @media (min-width: ${horizontalSmallMin}) {
                > div {
                    width: 29%;
                }
            }
            @media (min-width: ${horizontalFullMin}) {
                display: block;
                flex: auto;
                margin-bottom: 0;
                margin-right: auto;
                width: auto;

                > div {
                    width: auto;
                }
            }
        `};

    ${({ icons }) =>
        icons &&
        css`
            align-items: center;
            display: flex;
            margin-top: -20px;

            svg:first-child {
                margin-right: 20px;
            }

            @media (min-width: ${horizontalMin}) {
                align-items: start;
                margin-right: 0;
                margin-top: 0;
                flex-direction: column;
                width: auto;

                svg:first-child {
                    margin-bottom: 20px;
                    margin-right: 0;
                    margin-top: 10px;
                }
            }
        `};
`;

const Copyrights = styled.div`
    margin: 5px 0 10px;
`;

const StyledAustriaIcon = styled(austriaA4CIcon)`
    width: 77px;
`;

const StyledIgIcon = styled(igIcon)`
    vertical-align: middle;
    width: 17px;
`;
const StyledFbIcon = styled(fbIcon)`
    width: 20px;
`;

const SocialIcons = styled.div`
    align-items: center;
    display: flex;
    margin-left: -3px;
    margin-top: 12px;

    a {
        padding: 3px;

        &:first-child {
            margin-right: 7px;
        }
    }
    svg {
        display: block;
    }
`;

const AppLinks = styled.div`
    margin-top: 20px;
    max-width: 210px;

    a {
        display: block;
        overflow: hidden;

        &:first-child {
            margin: 0 10px 5px 0;
        }
        &:last-child {
            margin-left: -9px;
            img {
                margin: -9px 0 0 -10px;
            }
        }
    }
`;

export default Footer;
