import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useRef } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../constants/colors";

const ServicesLinks = ({ isMobile }) => {
    const listRef = useRef();
    const { t } = useTranslation();
    const servicesLinksTrans = t("header.servicesNav", { returnObjects: true });

    const handleToggleClick = (e) => {
        listRef.current.style.maxHeight = `${listRef.current.scrollHeight}px`;
        e.target.classList.toggle("isClosed");
    };

    return (
        <>
            <ServicesToggle
                className={`servicesToggle isClosed ${isMobile ? "mobile" : "desktop"}`}
                isMobile={isMobile}
                onClick={handleToggleClick}
            >
                <Trans>header.services</Trans>
            </ServicesToggle>
            <List className="servicesList isClosed" ref={listRef}>
                {Object.values(servicesLinksTrans).map((link, i) => {
                    return (
                        <li key={i}>
                            <Link to={link.url}>{link.name}</Link>
                        </li>
                    );
                })}
            </List>
        </>
    );
};

const List = styled.ul`
    list-style: none;
    margin: 0;
    max-height: 0;
    overflow: hidden;
    padding: 0;
    transition: max-height ease-out 300ms;
`;

const ServicesToggle = styled.span`
    cursor: pointer;

    &.isClosed + ul.servicesList {
        max-height: 0 !important;
    }

    ${({ isMobile }) =>
        isMobile &&
        css`
            position: relative;

            :after {
                border: solid ${colors.primary};
                border-width: 0 2px 2px 0;
                content: "";
                display: inline-block;
                margin-top: -6px;
                padding: 3px;
                position: absolute;
                right: 3px;
                top: 50%;
                transform: rotate(45deg);
                transition: transform ease-in-out 50ms;
            }
            &.isClosed:after {
                transform: rotate(-45deg);
            }
        `};
`;

export default ServicesLinks;
