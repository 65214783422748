import { breakpoints } from "./breakpoints";
import { colors } from "./colors";
import { shadows } from "./shadows";
import { space } from "./space";
import { fontSizes } from "./typography";

// https://styled-system.com/theme-specification
export const theme = {
    breakpoints,
    colors,
    space,
    fontSizes,
    shadows,
};
