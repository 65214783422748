import React, { useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import GlobalStyles from "../assets/global-styles";
import { theme } from "../config/theme";
import Footer from "./Footer";
import Header from "./Header";
import { useLocation } from "@reach/router";
import GtmService from "../service/gtmService";

const Layout = ({ children }) => {
    const { pathname } = useLocation();

    useEffect(() => {
        GtmService.instance.trackEvent({
            event: "UserViewPage",
        });
    }, [pathname]);

    return (
        <>
            <GlobalStyles />
            <ThemeProvider theme={theme}>
                <Wrapper>
                    <Header />
                    {children}
                    <Footer />
                </Wrapper>
            </ThemeProvider>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
`;

export default Layout;
