import React from "react";
import styled from "styled-components";
import { colors } from "../constants/colors";

const GoogleReviewsElement = () => {
    return (
        <Wrapper>
            <GoogleReviews
                href="https://www.google.at/search?q=miracl.at&source=hp&ei=tz1pYpywJIKalwTCjKzoCw&iflsig=AHkkrS4AAAAAYmlLx9KnBvmRoKo60LJfV_OgwDhEQN8V&ved=0ahUKEwjc5-rPpbT3AhUCzYUKHUIGC70Q4dUDCAc&uact=5&oq=miracl.at&gs_lcp=Cgdnd3Mtd2l6EAMyBAgAEA0yBAguEA0yBggAEA0QHjIGCAAQDRAeMgYIABANEB4yBggAEA0QHjIGCAAQDRAeMgYIABANEB4yBggAEA0QHjIGCAAQDRAeOg4IABCPARDqAhCMAxDlAlBXWFdg8gRoAXAAeACAAXmIAXmSAQMwLjGYAQCgAQKgAQGwAQY&sclient=gws-wiz#cobssid=s&lrd=0x2098d0e7ede900a9:0x8ada90d649b03ed5,1,,,"
                target="_blank"
                rel="noopener noreferrer"
            >
                Sehr gut (105)
            </GoogleReviews>
        </Wrapper>
    );
};

const Wrapper = styled.span`
    display: inline-block;
`;

const GoogleReviews = styled.a`
    background: url(/images/google-reviews.png) no-repeat;
    background-size: cover;
    box-sizing: border-box;
    color: #555;
    display: flex;
    height: 33px;
    font-size: 10px;
    font-weight: bold;
    padding: 0 0 2% 35%;
    width: 110px;

    align-items: flex-end;
    line-height: 1;

    span {
    }
`;

export default GoogleReviewsElement;
