import React from "react";
import styled from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";

import { colors } from "../../constants/colors";
import ServicesLinks from "./ServicesLinks";

const MobileNav = () => {
    return (
        <Wrapper>
            <ul>
                <li>
                    <ServicesLinks isMobile />
                </li>
                <li>
                    <Link to="/dienstleistungen/kreditrechner/">
                        <Trans>header.mortgageCalculator</Trans>
                    </Link>
                </li>
                <li>
                    <Link to="/faq/">
                        <Trans>header.faqs</Trans>
                    </Link>
                </li>
                <li>
                    <Link to="/ueber-uns/">
                        <Trans>header.aboutUs</Trans>
                    </Link>
                </li>
                <li>
                    <Link to="/kontakt/">
                        <Trans>header.contact</Trans>
                    </Link>
                </li>
                <li>
                    <Link to="/ratgeber/">
                        <Trans>header.blog</Trans>
                    </Link>
                </li>
            </ul>
        </Wrapper>
    );
};

const Wrapper = styled.nav`
    background: #fff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    color: ${colors.primary};
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;

    max-height: 0;
    transition: max-height ease-out 300ms;

    &.exit-active {
        max-height: 0 !important;
    }
    &.enter-done {
        max-height: none !important;
    }

    > ul {
        list-style: none;
        margin: 0;
        padding: 10px 40px 15px 62px;
    }
    li {
        border-top: 1px solid ${colors.primary};

        :first-child {
            border-top: 0;
        }

        > a,
        > span {
            display: block;
            padding: 11px 0;
        }
    }
    ul.servicesList {
        padding-left: 30px;
    }
`;

export default MobileNav;
