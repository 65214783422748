import styled, { css } from "styled-components";
import { breakpoints } from "../../constants/breakpoints";
import { colors } from "../../constants/colors";

export const ContentWrapperCss = css`
    box-sizing: border-box;
    margin: ${({ hasMarginTop }) => (hasMarginTop ? "50px" : "0")} auto 50px;
    max-width: 1400px;
    padding: 0 30px;
    width: 100%;

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin: ${({ hasMarginTop }) => (hasMarginTop ? "100px" : "0")} auto 100px;
        padding-left: 60px;
        padding-right: 60px;
    }
`;

export const ContentWrapper = styled.div`
    ${ContentWrapperCss};
`;

export const TextInputCss = css`
    -webkit-appearance: none;

    border: 2px solid ${colors.primary};
    border-radius: 20px;
    box-sizing: border-box;
    color: ${({ color }) => (color ? color : "inherit")};
    font-size: 16px;
    height: 40px;
    outline: none;
    padding: 0 20px;
    text-align: ${({ center }) => (center ? "center" : "inherit")};
    width: 100%;

    ::placeholder {
        color: #dfdfdf;
    }
`;

export const TextInput = styled.input`
    ${TextInputCss};
`;

const buttonSizes = {
    sm: css`
        font-size: 14px;
        padding: 7px 15px;
        border-radius: 6px;
    `,
    md: css`
        font-size: 16px;
        padding: 9px 25px;
        border-radius: 8px;
    `,
    lg: css`
        font-size: 18px;
        padding: 14px 25px;
        border-radius: 10px;
    `,
    xl: css`
        font-size: 22px;
        padding: 18px 25px;
        border-radius: 12px;
    `,
};
const arrowSizes = {
    sm: 2,
    md: 2,
    lg: 4,
    xl: 5,
};

export const ButtonCss = css`
    align-items: center;
    background: ${({ background }) => (background ? background : colors.primary)};
    border: 1px solid transparent;
    box-sizing: border-box;
    color: ${({ color }) => color || "#fff"};
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    ${({ size }) => buttonSizes[size] || buttonSizes.md}
    border-radius: ${({ radius }) => radius || "24px"};
    font-weight: ${({ fontWeight }) => fontWeight || "300"};
    text-align: center;

    :focus {
        opacity: 0.9;
    }

    ${({ height }) =>
        height &&
        css`
            height: ${height}px;
        `};

    ${({ secondary }) =>
        secondary &&
        css`
            background: ${colors.secondary};
            color: ${colors.secondaryText};
            font-weight: 500;
        `};

    ${({ blue }) =>
        blue &&
        css`
            background: ${colors.blue};
            font-weight: 500;
        `};

    ${({ transparentBg }) =>
        transparentBg &&
        css`
            background: transparent;
            border: 1px solid ${({ blue }) => (blue ? colors.blue : colors.primary)};
            color: ${({ blue }) => (blue ? colors.blue : colors.primary)};
            font-weight: 500;
            padding-top: 8px;
            padding-bottom: 8px;
        `};

    ${({ arrowRight }) =>
        arrowRight &&
        css`
            padding-right: 48px;
            position: relative;

            :after {
                border: solid ${({ secondary, color }) => color || (secondary ? colors.secondaryText : "#fff")};
                border-width: 0 2px 2px 0;
                content: "";
                display: inline-block;
                padding: ${({ size }) => `${arrowSizes[size] || arrowSizes.md}px`};
                position: absolute;
                right: 25px;
                transform: rotate(-45deg);
                transition: transform ease-in-out 50ms;
            }
        `};
`;

export const Button = styled.button`
    ${ButtonCss};
`;
export const ButtonOutline = styled.button`
    ${ButtonCss};
    background-color: transparent;
    border-color: ${({ background }) => (background ? background : colors.primary)};
    color: ${({ background }) => (background ? background : colors.primary)};
`;
export const ButtonLink = styled.a`
    ${ButtonCss};
`;
export const ButtonOutlineLink = styled.a`
    ${ButtonCss};
    background-color: transparent;
    border-color: ${({ background }) => (background ? background : colors.primary)};
    color: ${({ background }) => (background ? background : colors.primary)};
`;

export const CenterBlock = styled.div`
    text-align: center;
`;

export const H1Css = css`
    color: ${({ color }) => (color ? color : colors.primary)};
    font-size: 24px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 30px;
    text-align: ${({ center }) => (center ? "center" : "inherit")};

    @media (min-width: ${breakpoints.tabletMin}) {
        font-size: 36px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
        font-size: 44px;
        letter-spacing: -1.2px;
    }
`;
export const H1 = styled.h1`
    ${H1Css};
`;

export const H2Css = css`
    color: ${({ color }) => (color ? color : colors.primary)};
    font-size: 23px;
    font-weight: 500;
    margin: 0 0 26px;
    text-align: ${({ center }) => (center ? "center" : "inherit")};

    @media (min-width: ${breakpoints.tabletMin}) {
        font-size: 28px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
        font-size: 36px;
    }
`;
export const H2 = styled.h2`
    ${H2Css};
`;

export const H3Css = css`
    color: ${({ color }) => (color ? color : colors.primary)};
    font-size: 21px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 14px;
    text-align: ${({ center }) => (center ? "center" : "inherit")};

    @media (min-width: ${breakpoints.tabletMin}) {
        font-size: 25px;
    }

    @media (min-width: ${breakpoints.desktopMin}) {
        font-size: 28px;
    }
`;
export const H3 = styled.h3`
    ${H3Css};
`;

export const GreyBg = styled.div`
    background: #f8f8f8;
`;

export const Columns2 = styled.div`
    @media (min-width: ${breakpoints.tabletBigMin}) {
        display: flex;
        justify-content: space-between;
    }
`;

export const Column1of2 = styled.div`
    &:first-child {
        margin-bottom: 30px;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        margin-bottom: 0;
        width: 48%;
    }
`;
