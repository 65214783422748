import React from "react";

import Logo from "../icons/miracl-logo.svg";
import { colors } from "../../constants/colors";

const MiraclLogo = ({ color, width, height }) => {
    return <Logo fill={color} width={width} height={height} />;
};

MiraclLogo.defaultProps = {
    color: colors.primary,
    width: 70,
    height: 22,
};

export default MiraclLogo;
